<template>
  <div class="add-payment-auth">
    <CategoryTitle :category="category" />
    <div>
      <!-- id: 12 monetaweb, 13 monetawebpaypal -->
      <PaymentTypeList
        :modality="'addAuth'"
        :options="paymentTypeListOptions"
        :filteredPaymentTypeIds="config.addAuthPaymentTypeIds"
      />
    </div>
  </div>
</template>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";

import categoryMixins from "~/mixins/category";

export default {
  name: "MyPaymentMethods",
  mixins: [categoryMixins],
  components: {
    PaymentTypeList,
    CategoryTitle
  },
  data() {
    return {
      config: null,
      paymentAuthList: [],
      paymentTypeListOptions: {
        flatPanels: true,
        showCardLogos: true,
        hidePrevButton: true,
        showRememberCard: false,
        showAddNewCardWarning: false
      }
    };
  },
  created() {
    this.config = global.config;
  }
};
</script>
